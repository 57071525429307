// Header.js
import Header from "../component/Nevbar";
import Footer from "../component/Footer";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
const BlogDetails = () => {
  const [language, setLanguage] = useState("Arabic");
  const [fontFamilyStyle, setFontFamily] = useState("Proxima Soft");
  useEffect(() => {
    const header = document.querySelector(".page-header");
    const toggleClass = "is-sticky";

    const handleScroll = () => {
      const currentScroll = window.pageYOffset;
      if (currentScroll > 150) {
        header.classList.add(toggleClass);
      } else {
        header.classList.remove(toggleClass);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const location = useLocation();
  const { blog } = location.state || {};

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language") || "Arabic";
    setLanguage(savedLanguage);
    setFontFamily(
      savedLanguage === "Arabic" ? "Aref Ruqaa, serif" : "Proxima Soft"
    );
  }, []);

  return (
    <>
      <Header
        language={language}
        setLanguage={setLanguage}
        fontFamilyStyle={setFontFamily}
      />

      <section className="pt-5 pb-5">
        <div className="container">
          <div className="col-12" style={{ fontFamily: fontFamilyStyle }}>
            {language === "Arabic" ? (
              <h6
                class="headline text-white pb_40 text-center"
                style={{ fontFamily: fontFamilyStyle }}
              >
                {blog?.ar_title}
              </h6>
            ) : (
              <h6
                class="headline text-white pb_40 text-center"
                style={{ fontFamily: fontFamilyStyle }}
              >
                {blog?.title}
              </h6>
            )}
            <div className="blog-detail-img">
              <img src={blog.blog_image} alt="image" />
            </div>
            {/* <h4 className="text-white font_500 mb-3">Description</h4> */}
            <p className="text-white mb-3">
              <i class="fa-regular fa-calendar-days pe-2"></i>{" "}
              {new Date(blog.insertdate).toLocaleDateString()}
            </p>
            {language === "Arabic" ? (
             <p className="text-white mb-3" style={{ direction: 'rtl' }}>
             {blog?.ar_description}
           </p>
           
            ) : (
              <p className="text-white mb-3">{blog?.description}</p>
            )}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default BlogDetails;
