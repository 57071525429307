import React, { useEffect, useState, memo } from 'react';

const Pagination = memo(function Pagination({ per_page, pageCount, onPageChange, page, lableName }) {

    const [currentPage, setCurrentPage] = useState();
    const totalPages = Math.ceil(pageCount / per_page) || 4;

    useEffect(() => {
        setCurrentPage(page)
    }, [page]);

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setCurrentPage(newPage);
            onPageChange(newPage);
        }
    };

    const renderPageItems = () => {

        const pageItems = [];
        const maxVisiblePages = 3;
        let startPage = currentPage - Math.floor(maxVisiblePages / 2);
        startPage = Math.max(startPage, 1);
        const endPage = Math.min(startPage + maxVisiblePages - 1, totalPages);

        if (endPage - startPage < maxVisiblePages - 1) {
            startPage = Math.max(endPage - maxVisiblePages + 1, 1);
        }

        for (let onepage = startPage; onepage <= endPage; onepage++) {
            const isDataAvailable = pageCount > 0;
            const isActive = currentPage === onepage;

            if (isDataAvailable) {

                const buttonClass = `page-link font_18 ${isActive ? 'active-button' : ''}`;
                const listItemClass = `page-item ${isActive ? 'active' : ''}`;

                pageItems.push(
                    <li key={onepage} className={listItemClass}>
                        <button
                            className={buttonClass}
                            onClick={() => !isActive && handlePageChange(onepage)}
                            disabled={isActive}
                        >
                            {onepage}
                        </button>
                    </li>
                );
            }
        }
        return pageItems;
    };

    return (
        <>
            {per_page < pageCount ? (<>
                <div className="mt-3 showing-results d-flex justify-content-between">
                    <h5 className=''> Showing {Math.min(page * per_page, pageCount) || 0} of {pageCount || 0} {lableName}</h5>

                    <nav aria-label="Page navigation example">
                        <ul className="pagination">
                            {currentPage !== 1 && (
                                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                    <button className="page-link" aria-label="Previous" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                                        <span aria-hidden="true">«</span>
                                    </button>
                                </li>
                            )}
                            {renderPageItems()}
                            {currentPage !== totalPages && (
                                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                    <button className="page-link" aria-label="Next" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                                        <span aria-hidden="true">»</span>
                                    </button>
                                </li>
                            )}
                        </ul>
                    </nav>
                </div>
            </>) : null
            }

        </>);

});

export default Pagination;