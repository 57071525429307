import React, { memo } from 'react';
import { DNA } from 'react-loader-spinner';

export default memo(function Spinner({ message, isActive }) {
    if (!isActive) return <></>;
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '80vh',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 9999,
        }}>
           <DNA
            visible={true}
            height="80"
            width="80"
            ariaLabel="dna-loading"
            wrapperStyle={{}}
            wrapperClass="dna-wrapper"
  />
            {message && <h4 className='mt-2' style={{ color: 'black', fontSize: '18px' }}>{message}</h4>}
        </div>
    );
})