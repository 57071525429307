import { Routes, Route } from "react-router-dom";
import { Suspense, lazy } from "react";
import Blog from "../pages/Blog";
import BlogDetails from "../pages/BlogDetails";

export const Loadable = (Component) => (props) => {
  return (
    <Suspense>
      <Component {...props} />
    </Suspense>
  );
};

const Home = Loadable(lazy(() => import("../pages")));

const Router = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog_details" element={<BlogDetails />} />
      </Routes>
    </>
  );
};

export default Router;
