// Header.js
import React from "react";
import { Link } from "react-router-dom";

const Header = ({ language, setLanguage, fontFamilyStyle }) => {
  const handleLanguageChange = (event) => {
    const lang = event.target.value;
    setLanguage(lang);
    localStorage.setItem("language", lang);
    fontFamilyStyle(lang === "Arabic" ? "Aref Ruqaa, serif" : "Proxima Soft");
  };

  return (
    <header className="page-header px-md-4 py-md-3 py-2">
      <div className="container-fluid">
        <nav
          className="navbar navbar-expand-md"
          style={{ fontFamily: fontFamilyStyle }}
        >
          <div className="mobile_nav d-md-none">
            <button
              className="btn toogle_btn d-md-none"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasExample"
              aria-controls="offcanvasExample"
            >
              <span>
                <img src="assets/imges/menu.svg" alt="menuicon" />
              </span>
            </button>
          </div>
          <div
            className="offcanvas offcanvas-start"
            tabIndex={-1}
            id="offcanvasExample"
            aria-labelledby="offcanvasExampleLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title yellow" id="offcanvasExampleLabel">
                <a href="index.html">
                  <img src="assets/imges/logo.svg" alt="logo" width={62} />
                </a>
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              />
            </div>
            {language === "English" ? (
              <div className="offcanvas-body">
                <nav className="navbar menubar">
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <a className="nav-link active" href="/#home">
                        Home
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/#Features">
                        Features
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="https://dev.school.khatt.app/sign_up">
                        School
                      </a>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/blog">
                        Blog
                      </Link>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/#">
                        Download
                      </a>
                    </li>

                    <li className="nav-item">
                      <a className="nav-link" href="/#Contcat-us">
                        Contact us
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            ) : (
              <div className="offcanvas-body">
                <nav className="navbar menubar">
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <a className="nav-link" href="/#Features">
                        الميزات
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="https://dev.school.khatt.app/sign_up">
                        المدارس
                      </a>
                    </li>

                    <li className="nav-item">
                      <Link className="nav-link" to="/blog">
                        مدونة
                      </Link>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/#">
                        تحميل
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/#Contcat-us">
                        اتصل بنا
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link active" href="/#home">
                        الواجهة
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            )}
          </div>

          {/* Language Select */}
          <div className="ms-auto me-2">
            <select
              className="form-select"
              value={language}
              onChange={handleLanguageChange}
              aria-label="Language select"
            >
              <option value="Arabic">عربي</option>
              <option value="English">English</option>
            </select>
          </div>

          <a className="navbar-brand" href="index.html">
            <img src="assets/imges/logo.svg" alt="logo" className="img-fluid" />
          </a>
        </nav>
      </div>
    </header>
  );
};

export default Header;
