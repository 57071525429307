import React from 'react';
import { Helmet } from "react-helmet";

const Footer = () => {
  return (
    <>
    <Helmet>
        <script>{`
                $(document).ready(function() {
                    let value = new Date().getFullYear();
                    document.getElementById("Footer_DATE").innerHTML = value;
                });
        `}</script>
      </Helmet>
    <footer>
      <p className="text-white text-center py-4 fw_4">
        ©<label id='Footer_DATE'></label> Khat. All rights reserved.
      </p>
    </footer>
    </>
  );
};

export default Footer;
